import { graphql } from 'gatsby'
import React from 'react'
import ProjectHeader from '../../components/Header/ProjectHeader'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { mypx, col2, xgap } from '../../constants/spacing'
import {SimpleGrid, Grid, GridItem} from  '@chakra-ui/react'

import SEO from '../../components/SEO'
import DailyMotionPlayer from 'react-player/dailymotion'
import ProjectArrows, { ProjectLinkers } from '../../components/Header/ProjectArrows'
const preventClick = (e) => e.preventDefault()


const ProjectArrowz = React.lazy(() =>
  import("../../components/Header/ProjectArrows")
)
const CopperClub = ({data, location}) => {
    const isSSR = typeof window === 'undefined'
    const currentPath = location.pathname ? location.pathname : ''
    const dat = data.allWpProject.edges[0].node.hollandProjects
    const DesktopVid = dat.headerVideoDesktop.localFile.publicURL
    const MobileVid  = dat.headerVideoMobile.localFile.publicURL
    let image1;
    let image2;
    let image3;
    let image4;
    let image5;
    let image6;
    let image7;
    let image8;
    let image9;
    let image10;
    let image11;
    let image12;
    
    if(dat.image1) {
      image1 = getImage(dat.image1.localFile)
    } 
    if(dat.image2) {
       image2 = getImage(dat.image2.localFile)
    } 
    if(dat.image3) {
       image3 = getImage(dat.image3.localFile)
    } 
    if(dat.image4) {
       image4 = getImage(dat.image4.localFile)
    } 
    if(dat.image5) {
       image5 = getImage(dat.image5.localFile)
    } 
    if(dat.image6) {
       image6 = getImage(dat.image6.localFile)
    } 
    if(dat.image7) {
       image7 = getImage(dat.image7.localFile)
    } 
    if(dat.image8) {
       image8 = getImage(dat.image8.localFile)
    } 
    if(dat.image9) {
       image9 = getImage(dat.image9.localFile)
    } 
    if(dat.image10) {
       image10 = getImage(dat.image10.localFile)
    } 
    if(dat.image11) {
       image11 = getImage(dat.image11.localFile)
    } 
    if(dat.image12) {
       image12 = getImage(dat.image12.localFile)
    } 

    console.log(dat)

    return (
        <>
       <SEO title={dat.projecttitle} description={dat.projectdescription} image={image1} url="https://hollandhames.com/project/copper-club"/>
        <ProjectHeader desktopVid={DesktopVid} mobileVid={MobileVid} title={dat.projecttitle} description={dat.projectdescription} />
        <SimpleGrid gap={xgap} columns={1} px={mypx} pb={xgap}>
            <SimpleGrid columns={col2} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={image1} alt={dat.image1.altText} />
                <GatsbyImage onContextMenu={preventClick} image={image2} alt={dat.image2.altText} />
            </SimpleGrid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <img onContextMenu={preventClick} style={{maxWidth:"100%"}} src={dat.image3.sourceUrl} alt={dat.image3.altText} />
            </SimpleGrid>
            <SimpleGrid gap={xgap} columns={1}>
                <GatsbyImage onContextMenu={preventClick} image={image4} alt={dat.image4.altText} />
            </SimpleGrid>
            <Grid gap={xgap} templateColumns={["(1, 1fr)", null,null,null,"(2, 1fr)"]} templateRows={["(2, 1fr)", null, null, null, "(5, 1fr)"]}>
                <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,3]}>
                <GatsbyImage onContextMenu={preventClick} image={image5} alt={dat.image5.altText} />
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,2]} rowEnd={[2,null,null,null,5]}>
                    <img onContextMenu={preventClick} style={{width:'100%'}}src={dat.image6.sourceUrl} alt={dat.image6.altText} />
                    </GridItem>
            </Grid>
            <Grid gap={xgap} templateColumns={["(1, 1fr)", null,null,null,"(2, 1fr)"]} templateRows={["(2, 1fr)", null, null, null, "(5, 1fr)"]}>
                <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,2]}>
                    <GatsbyImage onContextMenu={preventClick} image={image7} alt={dat.image7.altText} />
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,1]} rowEnd={[2,null,null,null,5]}>
                    <GatsbyImage onContextMenu={preventClick} image={image8} alt={dat.image8.altText} />
                </GridItem>
            </Grid>
        </SimpleGrid>
        

        
        <ProjectLinkers prevTitle='NFL Hall of Fame' prevUrl='/project/nfl' nextTitle='Explore' nextUrl='/explore' />
      
   
        </>
    )
}

export default CopperClub
export const query = graphql`
{
    allWpProject(filter: {slug: {eq: "the-copper-club"}}) {
      edges {
        node {
          title
          hollandProjects {
            projecttitle
            projectdescription
            projectcategories
            image1 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image3 {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image4 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image5 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image6 {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image7 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image8 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image9{
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image10 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            headerVideoDesktop {
              localFile {
                publicURL
              }
            }
            headerVideoMobile {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`